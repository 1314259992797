import React, { Component } from 'react';
import HelmetEx from '../../components/helmet-ex';
import Layout from '../../components/layout';
import ServiceRow from '../../components/service-row';
import HunterDouglasBlindsImage from '../../images/photos/products/hunter-douglas-blinds.png';
import HunterDouglasDesignImage from '../../images/photos/products/hunter-douglas-design.png';
import HunterDouglasShadesImage from '../../images/photos/products/hunter-douglas-shades.png';
import HunterDouglasSheersImage from '../../images/photos/products/hunter-douglas-sheers.png';
import HunterDouglasShuttersImage from '../../images/photos/products/hunter-douglas-shutters.png';
import SubmenuList from '../../components/submenu-list';
import Config from '../../config';

class HunterDouglasWindowFashions extends Component {
    render() {
        const route = Config.Services.ProductRoutes.FeaturedProjects;
        return (
            <Layout>
                <div className="hunter-douglas-window-fashions-page">
                    <HelmetEx>
                        <div metadata="title">Horizontal & Vertical Blinds| Gliding Window Panels | Elmwood Park, NJ</div>
                        <div metadata="keywords">hunter dougles,blinds,shading,shades,bergen county, new jersey,NJ,window,panels,gliders,Screen,honycomb</div>
                        <div metadata="description">
                            Hunter Douglas blinds set the gold standard of quality, 
                            offering maximum view-through when opened, and optimal privacy when closed.
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h1>HUNTER DOUGLAS WINDOW FASHIONS</h1>
                                            {/* <h3></h3> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="navigation-section">
                        <div className="container">
                            <div className="row">
                                <SubmenuList activePath={route} />
                            </div>
                        </div>
                    </section>
                    <section className="below-navigation-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow flip title="HUNTER DOUGLAS BLINDS" src={HunterDouglasBlindsImage}>
                                        <p>
                                            We proudly carry Hunter Douglas Blinds, which offer the
                                            industry's largest selection of blinds made of genuine
                                            hardwoods, aluminum, vinyl and fabrics.
                                        </p>
                                        <p className="margin-top-60px">
                                            Hunter Douglas blinds set the gold standard of quality -- offering
                                            maximum view-through when opened, and optimal privacy when closed.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="fade-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <h4 className="sub-title">
                                                Hunter Douglas horizontal blinds
                                            </h4>
                                            <p>
                                                Are ideal for windows that are taller than they
                                                are wide. You can choose blinds made from
                                            </p>
                                            <p>
                                                Product lines include,
                                            </p>
                                            <ul className="pl-0 bullets">
                                                <li>Country Woods&reg; Genuine Woods blinds,</li>
                                                <li>EverWood&reg; 2" blinds,</li>
                                                <li>Reveal&trade; with MagnaView&trade;,</li>
                                                <li>Lightlines&reg; aluminum blinds with the de</li>
                                                <li>
                                                    Light&trade; design that hides cord holes to block
                                                    unwanted light, and more.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <h4 className="sub-title">
                                                Hunter Douglas vertical blinds
                                            </h4>
                                            <p>
                                                Are well suited for windows that are wider than they
                                                are tall and side from side to side. Our section
                                                includes blinds made from sheer, woven and room
                                                darkening fabrics, as well as from vinyl, faux
                                                wood, genuine wood and aluminum.
                                            </p>
                                            <p>Product lines include</p>
                                            <ul className="pl-0 bullets">
                                                <li>Cadence&reg; Soft Vertical Blinds</li>
                                                <li>Somner&reg; Custom Vertical Blinds</li>
                                                <li>Crosswinds&reg; wood vertical blinds, and more.</li>
                                            </ul>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <h4 className="sub-title">
                                                Skyline&reg; Gliding Window Panels
                                            </h4>
                                            <p>
                                                With clean lines and a sleek contemporary look, Skyline Gliding Window Panels offer the
                                                perfect sliding window panel solution for both large and small window expanses.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow title="HUNTER DOUGLAS HONEYCOMBS" src={HunterDouglasDesignImage}>
                                        <p>
                                            Hunter Douglas' innovative honeycomb shade collection offers
                                            timeless beauty and delivers the industry's highest
                                            energy-efficiency rating
                                        </p>
                                        <p className="margin-top-60px">
                                            Available in vertical and horizontal pleats, Hunter Douglas honeycombs come in a wide range
                                            of fabric types, colors, shapes and operating systems.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="fade-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <h4 className="sub-title">
                                                Duette&reg; Architella&reg; Honeycombs
                                            </h4>
                                            <p>
                                                Feature a state-of-the-art, patented, honey-comb-within-a-honeycomb
                                                design and the industry's highest level of energy efficiency.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <h4 className="sub-title">
                                                Duette&reg; honeycomb shades
                                            </h4>
                                            <p>
                                                Offer a wide array of fabrics, pleat sizes, colors and textures,
                                                in addition to superior energy efficiency.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <h4 className="sub-title">
                                                Applause&reg; honeycomb shades
                                            </h4>
                                            <p>
                                                Are the right choice when looking for energy savings and value
                                                with the enduring quality of the Hunter Douglas brand.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow flip title="HUNTER DOUGLAS SHADES" src={HunterDouglasShadesImage}>
                                        <p>
                                            Hunter Douglas shades providea beautiful  finishing touch for
                                            virtually any window. They offer stylish design, energy efficiency,
                                            sun protection - including room darkening fabrics - and privacy.
                                        </p>
                                        <p className="margin-top-60px">
                                            Our shade collection include Roman Shades, Roller Shades, Pleated Shades,
                                            Honeycomb Shades, Horizontal Shades and Vertical Shades.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="fade-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <h4 className="sub-title">
                                                Hunter Douglas Roman Shades
                                            </h4>
                                            <p>
                                                Combine the look of a fine drapery with the ease and
                                                convenience of a shade.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <h4 className="sub-title">
                                                Hunter Douglas Roller Shades
                                            </h4>
                                            <p>
                                                Feature excellent light control while providing a clean, stylish look.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <h4 className="sub-title">
                                                Hunter Douglas Pleated Shades
                                            </h4>
                                            <p>
                                                Is a popular style featuring crisp, pleated
                                                fabrics in an extensive selection of colors and styles, including weaves, patterns and textures
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 col-md-4 margin-top-60px">
                                            <h4 className="sub-title">
                                                Hunter Douglas Honeycomb Shades
                                            </h4>
                                            <p>
                                                Deliver the industry's highest energy-efficiency
                                                rating and come in a wide range of fabric types,
                                                colors and shades
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4 margin-top-60px">
                                            <h4 className="sub-title">
                                                Hunter Douglas Horizontal Shades
                                            </h4>
                                            <p>
                                                Are ideal for windows in your home that are taller than they are wide.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4 margin-top-60px">
                                            <h4 className="sub-title">
                                                Hunter Douglas Vertical Shades
                                            </h4>
                                            <p>
                                                Are the perfect solution for sliding glass doors and specialty shapes
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow title="HUNTER DOUGLAS SHEERS" src={HunterDouglasSheersImage}>
                                        <p>
                                            For transforming ordinary sunlight into extraordinary room ambience,
                                            nothing surpasses Hunter Douglas sheer window treatments.
                                        </p>
                                        <p className="margin-top-60px">
                                            The Hunter Douglas sheer collection—including shadings, sheers, louvers and
                                            panels—are made with a wide selection of soft fabrics, including opaque,
                                            translucent, semi-sheer and room darkening materials.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="fade-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <p className="text-center paragraph-title">Choices include the following Hunter Douglas product lines:</p>
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <h4 className="sub-title">
                                                Silhouette® Window Shadings
                                            </h4>
                                            <p>
                                                feature the Signature S-Vane™ and are available in a wide range of
                                                beautiful fabrics, including casual linen, woven and light dimming materials.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <h4 className="sub-title">
                                                Pirouette® Window Shadings
                                            </h4>
                                            <p>
                                                are made of soft, horizontal fabric vanes attached to a single
                                                sheer backing and control light in an entirely new way.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <h4 className="sub-title">
                                                Luminette® Privacy Sheers
                                            </h4>
                                            <p>
                                                marry the beauty of sheers with the privacy of soft draperies and are especially
                                                suited for larger, rectangular windows and sliding glass doors.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4 margin-top-60px">
                                            <h4 className="sub-title">
                                                Skyline™ Gliding Window Panels
                                            </h4>
                                            <p>
                                                feature clean lines and a sleek contemporary look, offering the perfect sliding
                                                window panel solution for both large and small window expanses.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow flip title="HUNTER DOUGLAS SHUTTERS" src={HunterDouglasShuttersImage}>
                                        <p>
                                            Hunter Douglas interior shutters provide superior sun protection, light control and privacy -- and a beautiful and classic look that never goes out of style.
                                        </p>
                                        <p className="margin-top-60px">
                                            Our shutter collection, including the popular plantation shutter style, features traditional beauty and styling, long-lasting finishes and exceptional craftsmanship.
                                        </p>
                                        <p className="margin-top-60px">
                                            Shutters are available in premium hardwood and poly-satin, faux wood compounds.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="fade-bg">
                        <div className="container">
                            <p className="text-center paragraph-title">
                                Choices include the following Hunter Douglas product lines:
                            </p>
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <h4 className="sub-title">
                                        Heritance® Hardwood Shutters
                                    </h4>
                                    <p>
                                        are crafted for maximum strength and durability, using Truemill® dovetail joinery and finished with any of our 100 Integra paint and stain finishes.
                                    </p>
                                </div>
                                <div className="col-12 col-md-4">
                                    <h4 className="sub-title">
                                        NewStyle® Hybrid Shutters
                                    </h4>
                                    <p>
                                        combine the great style of wood with the strength, stability, and straightness of advanced modern-day materials.
                                    </p>
                                </div>
                                <div className="col-12 col-md-4">
                                    <h4 className="sub-title">
                                        Palm Beach™ Polysatin Shutters
                                    </h4>
                                    <p>
                                        are constructed from UV resistant polysatin compounds and are guaranteed
                                        never to warp, fade, chip or peel, regardless of extreme heat or moist conditions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section >
                </div >
            </Layout >
        )
    }
}

export default HunterDouglasWindowFashions;